import React from 'react';
import {Link} from "react-router-dom";
import Themes from '../themes/Themes';
import "./TopMenu.scss";

function TopMenu() {
    const contactForm = "afb8a93e-138a-4f8b-a7de-387a6b0d1d9a";

    return (
        <div className="top-menu">
            <Themes/>
            {/* <a className={'top-menu-icon'} target="_blank" href="https://discord.com"><div className="discord"></div></a> */}
            <Link to={{
                pathname: `/`
            }}>
                Scripts
            </Link>
            <a href="https://www.youtube.com/channel/UCCQV41S86u3yO4tSQ7UksHQ" target="_blank">
                Subscribe
            </a>
            <Link to={{
                pathname: `/info`
            }}>
                Info
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>Contact</Link> : null}
        </div>
    );
}

export default TopMenu;
